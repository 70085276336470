<template>
  <b-overlay :show="showOverlay">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        title="Delete Field"
        @ok.prevent="deleteField"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this product field ?</span>
        </div>
      </div>
    </b-modal>
    <b-card v-if="productDetail" class="data-edit-wrapper">
      <h2>Manage Product</h2>
      <b-form class="mt-2">
        <validation-observer ref="updateProduct" tag="div">
          <b-row class="p-1">
            <b-col class="" md="4">
              <b-form-group
                  label="Name"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="productDetail.name"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col cols="12">
            <b-row>
              <b-col v-for="(item,idx) in currencies" cols="12" md="3">
                <b-form-group
                    :label="`Price In ${item.name}`"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      :ref="`price${idx}`"
                      :value="getPriceValue(item) ? getPriceValue(item).priceValue : ''"
                      type="number"
                  />

                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
          <b-col cols="3">
            <b-button
                class=" "
                variant="primary"
                @click="updateProduct"
            >
              Submit
            </b-button>
          </b-col>
        </validation-observer>
      </b-form>
    </b-card>
    <b-card v-if="productDetail">
      <b-row>
        <b-col cols="12" md="12">
          <h2>Product Multiple State (If Necessary For Cashback)</h2>
        </b-col>
        <b-col class="my-1" cols="3" md="12">
          <b-button
              class="btn-tour-skip mr-1"
              variant="primary"
          >
            <b-link
                :to="`/apps/games/game-service-categories/game-service/products/product-slider/manage-slider?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${$route.query.productId}`"
            >
              <span class="mr-25 align-middle text-white">Manage</span>
            </b-link>

          </b-button>
        </b-col>

      </b-row>
    </b-card>
    <b-card v-if="productDetail">
      <b-row>
        <b-col cols="12" md="12">
          <h2>Product Fields</h2>
        </b-col>
        <b-col class="my-1" cols="3" md="12">
          <b-button
              class="btn-tour-skip mr-1"
              variant="primary"
          >
            <b-link
                :to="`/apps/games/game-service-categories/game-service/products/product-field/create-product-field?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${productDetail.productId}`"
            >
              <span class="mr-25 align-middle text-white">Create</span>
            </b-link>

          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="productFields"
              :sort-by.sync="sortBy"
              bordered
              class="position-relative"

              empty-text="Nothing found !"
              primary-key="id"
              responsive
              show-empty
              striped
          >

            <template #cell(productFieldDetails)="data">
              <span>{{ data.item.productFieldDetails[0].name }}</span>
            </template>
            <template #cell(operation)="data">
              <div class="d-flex align-items-center  w-100">
                <b-link
                    :to="`/apps/games/game-service-categories/game-service/products/product-field/manage-product-field?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${$route.query.productId}&productFieldDetailId=${data.item.productFieldDetails[0].productFieldDetailId}`"
                >
                  <feather-icon
                      class="text-primary"
                      icon="EditIcon"
                      size="20"
                  />
                </b-link>
                <span v-b-modal.modal-delete class="cursor-pointer mx-1"
                      @click="setSelectedField(data.item)"
                >
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
              </div>
            </template>
          </b-table>

        </b-col>
        <b-col cols="12">
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                  class="d-flex align-items-center justify-content-center"
                  cols="12"
                  sm="12"
              >
                <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  cols="12"
                  sm="6"
              >
                <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalCount"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetProductDetail, UpdateProduct } from '@/libs/Api/product'
import { GetAllCurrencies } from '@/libs/Api/Currencies'
import { DeleteProductField, GetProductFieldsByProductId } from '@/libs/Api/productField'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-product',
  title: 'manage product',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      totalCount: null,
      perPage: 10,
      selectedField: null,
      sortBy: '',
      currentPage: 1,
      productFields: null,
      productDetail: null,
      currencies: null,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: true
        },
      ],
      myTableColumns: [
        {
          key: 'productFieldId',
          label: 'Field Id',
          sortable: true
        },
        {
          key: 'productFieldDetails',
          label: 'name',
          sortable: true
        },

        {
          key: 'operation',
          label: 'operation'
        },
      ],
      updateProductPayload: {
        productId: null,
        name: '',
        gameServiceId: null,
        updateProductPrice: []
        // {
        //   "productPriceId": 0,
        //     "priceValue": 0,
        //     "currencyId": 0
        // }
      }
    }
  },
  computed: {},
  async created() {
    await Promise.all([
      this.getProductDetail(),
      this.getAllCurrencies(),
      this.getProductFieldsByProductId()
    ])
  },
  watch: {
    currentPage: function (val) {
      this.getProductFieldsByProductId()
    }
  },
  methods: {
    async getProductFieldsByProductId() {
      let _this = this
      _this.showOverlay = true

      let getProductFieldsByProductId = new GetProductFieldsByProductId(_this)
      let data = {
        productId: _this.$route.query.productId,
        pageNumber: _this.currentPage,
        count: _this.perPage
      }
      getProductFieldsByProductId.setParams(data)
      await getProductFieldsByProductId.fetch(function (content) {
        _this.showOverlay = false
        _this.productFields = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

    createPayload() {
      this.updateProductPayload.name = this.productDetail.name
      this.updateProductPayload.productId = this.productDetail.productId
      this.updateProductPayload.gameServiceId = this.$route.query.gameServiceId
      this.currencies.forEach((item, idx) => {
        this.updateProductPayload.updateProductPrice.push({
          currencyId: item.id,
          productPriceId: this.getPriceValue(item) ? this.getPriceValue(item).productPriceId : '',
          priceValue: this.$refs[`price${idx}`][0]?.$el.value
        })
      })
      return this.updateProductPayload
    },
    getPriceValue(item) {
      let idx = this?.productDetail?.prices.findIndex(e => e.currencyId === item.id)
      if (idx > -1) {
        return this.productDetail.prices[idx]
      } else {
        return null
      }

    },
    async getAllCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: 1,
        count: 20,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async deleteField() {
      let _this = this
      _this.showOverlay = true
      let deleteProductField = new DeleteProductField(_this)
      deleteProductField.setParams({ productFieldId: _this.selectedField.productFieldId })
      await deleteProductField.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `successfully deleted`,
          },
        })
        _this.getProductFieldsByProductId()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setSelectedField(item) {
      this.selectedField = JSON.parse(JSON.stringify(item))
    },
    async updateProduct() {
      let _this = this
      Helper.validateForm(_this, 'updateProduct', async () => {

        _this.showOverlay = true
        let updateProduct = new UpdateProduct(_this)
        let data = this.createPayload()
        updateProduct.setParams(data)
        await updateProduct.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.updateProductPayload = {
              productId: null,
              name: '',
              gameServiceId: null,
              updateProductPrice: []
            }
            _this.getProductDetail()
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })

    },
    async getProductDetail() {
      let _this = this
      _this.showOverlay = true

      let getProductDetail = new GetProductDetail(_this)
      let data = {
        id: _this.$route.query.productId
      }
      getProductDetail.setParams(data)
      await getProductDetail.fetch(function (content) {
        _this.showOverlay = false
        _this.productDetail = content.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
  }
}
</script>

<style scoped>

</style>
